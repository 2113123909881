*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html,
body {
  overflow: hidden;
  height: 100%; }
#root{
  height: 100%
}
::-webkit-scrollbar {
  display: none; }

html {
  background-color: #fff;
  color: #212121;
  font-size: 26.6666666666667vw;
  -webkit-user-select: none;
  user-select: none; }

body {
  margin: 0;
  font-size: 0.14em;
  line-height: 1.5;
  font-family: PingFangSC-Regular, Hiragino Sans GB, sans-serif }

ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
form,
fieldset,
legend,
input,
textarea,
button,
p,
blockquote,
th,
td,
pre,
xmp {
  margin: 0;
  padding: 0; }

input,
textarea,
button,
select,
pre,
xmp,
tt,
code,
kbd,
samp {
  line-height: inherit;
  font-family: inherit; }

h1,
h2,
h3,
h4,
h5,
h6,
small,
big,
input,
textarea,
button,
select {
  font-size: inherit; }

address,
cite,
dfn,
em,
i,
optgroup,
var {
  font-style: normal; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  text-align: left; }

ul,
ol,
menu {
  list-style: none; }

fieldset,
img {
  border: 0;
  vertical-align: middle; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "\0020"; }

textarea,
pre,
xmp {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

textarea {
  resize: vertical; }

input,
textarea,
button,
select,
summary,
a {
  outline: 0 none; }

input,
textarea,
button,
select {
  color: inherit; }
  input:disabled,
  textarea:disabled,
  button:disabled,
  select:disabled {
    opacity: 1; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none; }

::-webkit-details-marker {
  display: none; }

mark {
  background-color: rgba(0, 0, 0, 0); }

a,
ins,
s,
u,
del {
  text-decoration: none; }

a,
img {
  -webkit-touch-callout: none; }

a {
  color: #00afc7; }

.g-clear::after,
.g-mod::after {
  display: block;
  overflow: hidden;
  clear: both;
  height: 0;
  content: "\0020"; }

@font-face {
  font-family: yofont;
  src: url(//ss.qunarzz.com/yo/font/1.0.3/yofont.woff) format("woff"), url(//ss.qunarzz.com/yo/font/1.0.3/yofont.ttf) format("truetype"); }

.yo-ico {
  font-family: yofont !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle; }

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  background: rgba(249, 250, 249, 1);
}
.head {
  width: 100%;
  height: 61px;
  min-height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.02);
}
.body {
  width: 100%;
  position: relative;
  flex: 1 1 auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.footerMargin {
  flex: 1 1 auto;
}
.footer {
  width: 100%;
  height: 61px;
  max-height: 61px;
  min-height: 61px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(195, 195, 195, 1);
  background: rgba(255, 255, 255, 1);
  line-height: 17px;
  margin-top: 30px;
}
//头部Logo样式
.logo {
  width: 900px;
  height: 14px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(75, 176, 81, 1);
  line-height: 14px;
}
//热招岗位样式
.section {
  width: 900px;
  height: 28px;
  margin-top: 24px;
  border-left: 4px solid #4bb051;
  padding-left: 8px;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 28px;
}
// 岗位详情卡片样式
.cardDiv {
  width: 900px;
  height: 362px;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  //   box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin-top: 16px;
  //   辅助线开始
  //   box-sizing: border-box;
  //   border: 1px solid #0ff;
  //   辅助线结束
}
.cardDiv1 {
  width: 852px;
  height: 338px;
  overflow: hidden;
}
.department {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(155, 155, 155, 1);
  line-height: 17px;
}
.namesalary {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name {
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(74, 74, 74, 1);
  line-height: 45px;
}
.salary {
  height: 45px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(75, 176, 81, 1);
  line-height: 50px;
}
.salaryUnit {
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(75, 176, 81, 1);
  line-height: 30px;
  margin-left: 8px;
}
.requirement {
  height: 18px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  line-height: 18px;
  margin-top: 10px;
}
.tags {
  height: 52px;
  line-height: 22px;
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}
.tag {
  height: 22px;
  width: auto;
  border-radius: 13px;
  border: 1px solid rgba(75, 176, 81, 0.5);
  box-sizing: border-box;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(75, 176, 81, 1);
  line-height: 22px;
  padding: 0 8px;
  margin-right: 8px;
  margin-bottom: 6px;
}
.requireTitle {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  margin-top: 14px;
}
.require {
  height: 18px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  line-height: 30px;
  margin-top: 18px;
  filter: blur(5px);
}
.qrcodeDiv {
  width: 100%;
  height: 149px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}
.qrcode {
  width: 100px;
  height: 100px;
  margin-top: -7px;
}
.desc {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
  margin-top: 17px;
}
.greenText {
  color: #4bb051;
}
